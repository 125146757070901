import { getAppSDK } from '@finn/ua-app';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { isMobileApp } from '@finn/ui-utils';
import {
  ButtonBase,
  CircularProgress,
  makeStyles,
  Theme,
} from '@material-ui/core';
import cn from 'classnames';
import { compareVersions } from 'compare-versions';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/client';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { MatchingIcon } from './MatchingIcon';
import { SocialLoginMethod } from './SocialLoginButtons';
// TODO design-system: refactor !important styles after migration to new typography APP-1443

type ButtonProps = {
  method: SocialLoginMethod;
};

const RESET_TIMEOUT = 3000;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
    justifyContent: 'center',
    border: '1px solid',
    padding: theme.spacing(2),
    borderRadius: '2px',
  },
  circularProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    color: 'inherit',
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export const SocialLoginButton: React.FC<ButtonProps> = ({ method }) => {
  const i18n = useIntl();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { asPath } = useRouter();
  const track = useTrackingStore((state) => state.track);

  useEffect(() => {
    const appSDK = getAppSDK();
    if (!appSDK) {
      return;
    }

    appSDK.stopLoading = () => {
      if (loading) {
        setLoading(false);
      }
    };
  }, [loading]);

  const onButtonClick = (loginMethod: SocialLoginMethod) => {
    setLoading(true);

    if (isMobileApp()) {
      const appSDK = getAppSDK();
      const version = appSDK?.getTrackingProps().app_version;
      const isWebFirstNavigation = version
        ? compareVersions(version, '1.47.0') >= 0
        : false;
      const withNativeAuth = version
        ? compareVersions(version, '1.48.0') >= 0
        : false;

      if (withNativeAuth) {
        appSDK?.sendMessageToApp({
          type: 'event:native_social_login',
          value: loginMethod,
        });
      } else if (isWebFirstNavigation) {
        const redirectTo = asPath.includes('/checkout/') ? asPath : 'account';
        window.location.assign(
          `/mobile/loginredirect?method=${loginMethod}&deepLink=${redirectTo}`
        );

        setLoading(false);
      } else {
        // for the app we created separate page which is opened in a browser and calls signIn method
        // we did this due to constraint how Next Auth works with callback URLs (keeping it in cookies)
        window.location.assign(
          `/mobile/loginredirect?method=${loginMethod}&deepLink=${
            asPath.includes('/mobile/checkout/')
              ? // remove /mobile/checkout so that app doesn't see it as checkout URL -> opens it in browser
                // ?undefined= comes from the app and is a bug, we remove it here and will fix it in next release
                asPath
                  .replace('/mobile/checkout/', '')
                  .replace('?undefined=', '')
              : 'subscribe'
          }#appkey=/b2b`
        );

        setLoading(false);
      }
    } else {
      signIn(method.toLowerCase()).then(() => {
        setTimeout(() => {
          setLoading(false);
        }, RESET_TIMEOUT);
      });
    }
    track(TrackingEventName.LOGIN_METHOD_CLICKED, {
      additionalProps: {
        loginMethod,
      },
    });
  };
  const loginText = i18n.formatMessage({
    id: `userAccount.button.${method}`,
  });

  return (
    <ButtonBase
      className={cn(
        classes.button,
        'body-16-semibold',
        'finn-social-button !border-pewter hover:bg-snow'
      )}
      onClick={() => {
        onButtonClick(method);
      }}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress
          className={classes.circularProgress}
          // A little hack to prevent CircularProgress from inlining styles,
          // which makes it impossible to redefine them in `classes`
          size={null as unknown as undefined}
        />
      ) : (
        <>
          <span className="mr-2 mt-1 inline-block">
            <MatchingIcon method={method} />
          </span>
          <span className="body-14-semibold">{loginText}</span>
        </>
      )}
    </ButtonBase>
  );
};
