import { Link } from '@finn/design-system/icons/link';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { ButtonBase, makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
import { LoginScreen, useLoginStore } from './store/useLoginStore';

const method = 'MagicLink';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
    justifyContent: 'center',
    border: '1px solid',
    padding: theme.spacing(2),
    borderRadius: '2px',
  },
}));

export const MagicLinkButton: React.FC = () => {
  const i18n = useIntl();
  const classes = useStyles();
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const track = useTrackingStore((state) => state.track);

  const onButtonClick = () => {
    track(TrackingEventName.LOGIN_METHOD_CLICKED, {
      additionalProps: {
        loginMethod: method,
      },
    });
    setLoginScreen(LoginScreen.magicLinkForm);
  };
  const loginText = i18n.formatMessage({
    id: `userAccount.button.magicLink`,
  });

  return (
    <ButtonBase
      className={cn(
        classes.button,
        'finn-social-button !border-pewter hover:bg-snow'
      )}
      onClick={() => {
        onButtonClick();
      }}
    >
      <Link className="mr-2" />
      <span className="body-14-semibold">{loginText}</span>
    </ButtonBase>
  );
};
