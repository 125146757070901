import { VisibilityFilled } from '@finn/design-system/icons/visibility-filled';
import { VisibilityOffOutlined } from '@finn/design-system/icons/visibility-off-outlined';
import { PasswordInput, useLoginFormTracker } from '@finn/ua-auth';
import { cn } from '@finn/ui-utils';
import { ButtonBase, makeStyles, Theme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

// TODO design-system: refactor !important styles after migration to new typography APP-1443
import { LoginScreen, useLoginStore } from './store/useLoginStore';

type Props = {
  label: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: { position: 'relative' },
  inlineButton: {
    position: 'absolute',
    top: theme.spacing(1.5),
    right: theme.spacing(1),
    padding: theme.spacing(1, 1),
    border: `1px solid transparent`,
    borderRadius: '2px',
  },
  forgetButton: {
    padding: theme.spacing(2, 0, 0),
  },
}));

export const PasswordField: React.FC<Props> = ({ label }) => {
  const loginScreen = useLoginStore((state) => state.loginScreen);
  const serverErrorCode = useLoginStore((state) => state.serverErrorCode);
  const setLoginScreen = useLoginStore((state) => state.setLoginScreen);
  const isRegistration = loginScreen === LoginScreen.registrationForm;
  const autoFocus = !isRegistration;
  const form = useFormContext();
  const i18n = useIntl();
  const [didFocus, setDidFocus] = useState(autoFocus);
  const classes = useStyles();
  const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);
  const [shouldShowShowHideIcon, setShouldShowShowHideIcon] = useState(false);
  const password = form.watch('password');
  const { trackForgotPasswordClick } = useLoginFormTracker();

  const onFocus = useCallback(() => {
    if (!didFocus) {
      setDidFocus(true);
    }
  }, [didFocus]);

  useEffect(() => {
    if (password?.length) {
      setShouldShowShowHideIcon(true);
    } else {
      setShouldShowShowHideIcon(false);
    }
  }, [password]);

  // on server error we want to show forget password link instead of show/hide icon
  useEffect(() => {
    if (serverErrorCode && !isRegistration) {
      setShouldShowShowHideIcon(false);
    }
  }, [serverErrorCode, isRegistration]);

  const onShowHideClick = useCallback(() => {
    setIsPasswordRevealed(!isPasswordRevealed);
  }, [isPasswordRevealed]);

  const onForgotPasswordClick = useCallback(() => {
    trackForgotPasswordClick({ source: 'unified-login' });
    setLoginScreen(LoginScreen.forgotPasswordForm);
  }, [setLoginScreen, trackForgotPasswordClick]);

  return (
    <div className={classes.root}>
      <Controller
        control={form.control}
        name="password"
        render={({ field, fieldState }) => (
          <>
            <PasswordInput
              id="password"
              label={i18n.formatMessage({ id: 'userAccount.field.password' })}
              enablePasswordStrengthCheck={didFocus && isRegistration}
              type={isPasswordRevealed ? 'text' : 'password'}
              inputProps={{
                autoComplete: 'off',
                'aria-autocomplete': 'list',
              }}
              validationError={fieldState?.error?.message}
              fullWidth
              autoFocus={autoFocus}
              onFocus={onFocus}
              {...field}
            />
            {shouldShowShowHideIcon && (
              <ButtonBase
                className={cn(
                  classes.inlineButton,
                  'focus-visible:border-pewter'
                )}
                onClick={onShowHideClick}
              >
                {isPasswordRevealed ? (
                  <VisibilityOffOutlined width={16} height={16} />
                ) : (
                  <VisibilityFilled width={16} height={16} />
                )}
              </ButtonBase>
            )}
            {!isRegistration && (
              <ButtonBase
                className={cn(classes.forgetButton, '!link-14')}
                onClick={onForgotPasswordClick}
              >
                {label}
              </ButtonBase>
            )}
          </>
        )}
      />
    </div>
  );
};
